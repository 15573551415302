<template>
    <div>
        <allProduct :genre="1" :classId="1" :good_type="2"></allProduct>
    </div>
</template>

<script>
import allProduct from "@/components/allProduct"
export default {
    components: { allProduct },
    name: '',
    data() {
        return {}
    },
    methods: {
    },
}

</script>
<style scoped></style>